<template>
    <div class="zhuqiao">
            <van-tabs v-model="active" sticky>
                <van-tab v-for="(item,index) in recruit" :key="index" :title="item.tab_name">
                    <div @click="toCheck(it)" v-for='it in item.tab_recruit' :key="it.recruit_id" class="zhuqiao-item" :style="{'backgroundImage': 'url(' + it.recruit_cover + ')'}">
                        <div class="zhuqiao-item-title">{{it.recruit_name}}</div>
                        <div>
                            <span>授课老师:</span>
                            <span>{{it.recruit_class_teacher_name}}</span>
                        </div>
                        <div class="zhuqiao-item-time">
                            <span>招募时间:</span>
                            <span>{{it.recruit_start}} - {{it.recruit_end}}</span>
                        </div>
                        <!-- <div>
                            <span>授课人数:</span>
                            <span>{{it.recruit_total}}人</span>
                        </div> -->
                        <div class="zhuqiao-item-price">
                            <span>授课价格:</span>
                            <span>¥ {{it.is_member?it.member_price:it.sale_price}} / 人</span>
                        </div>
                        <van-checkbox v-if="it.can_buy" class="zhuqiao-item-check" v-model="it.recruit_check" checked-color="#07c160"></van-checkbox>
                    </div>
                </van-tab>
            </van-tabs>
        <van-submit-bar :price="money" button-text="提交" @submit="onSubmit" />
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { wxPay } from '@/common/wx'
    import Loadings from '@/components/loading'
    import { Tab, Tabs, Checkbox, SubmitBar } from 'vant'
    export default {
        name:'zhuqiao',
        components: {
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Checkbox.name]: Checkbox,
            [SubmitBar.name]: SubmitBar,
            loadings:Loadings
        },
        data() {
            return {
                loading:false,
                recruit:[],
                active: 0,
                money:0,
                recruit_id:[],
            }
        },
        created () {
            this.getList()
        },
        methods:{
            getList(){
                this.loading = true
                let id = this.$route.query.id
                this.$api.recruit_zhuqiao({id}).then(res => {
                    this.recruit = res.data.recruit
                    this.loading = false
                }).catch(err=>{
                    this.loading = false
                })
            },
            toCheck(item){
                if(!item.can_buy){
                    return false
                }
                let price = item.is_member?item.member_price:item.sale_price
                if(!item.recruit_check){
                    item.recruit_check = true
                    this.money = Number(price) * 100 + Number(this.money)
                    this.recruit_id.push(item.recruit_id)
                }else{
                    item.recruit_check = false
                    this.money = Number(this.money) - Number(price) * 100
                    let index = this.recruit_id.indexOf(item.recruit_id)
                    this.recruit_id.splice(index,1)
                }
            },
            onSubmit(){
                if(this.recruit_id.length == 0){
                    this.$toast('请选择~')
                    return false
                }
                let survey_id = ''
                this.recruit.forEach(item=>{
                    item.tab_recruit.forEach(it=>{
                        if(it.recruit_id == this.recruit_id[0]){
                            survey_id = it.survey_id
                        }
                    })
                })
                localStorage.setItem('money',this.money)
                this.$router.push({path:'/survey',query:{id:survey_id,zhuqian:1,recruit_ids:JSON.stringify(this.recruit_id)}})
                // this.$api.payment_recruit({recruit_id:this.recruit_id}).then(res => {
                //     const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
                //     wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
                //     .then(res => {
                //         this.$toast.success('支付成功')
                //         this.$router.push({path:'/submitSuccess'})
                //     })
                //     .catch(err => {
                //         this.$toast.clear()
                //         if(err == 9){
                //             this.$toast.fail('取消支付')
                //         }else{
                //             this.$toast.fail('支付失败')
                //         }
                //         setTimeout(() => {
                            
                //         }, 1500)
                //         console.error(err)
                //     })
                // })
                // .catch(err => {
                //     console.error(err)
                // })
            }
        },
        beforeRouteEnter(to, from, next) {
            let title = to.query.title
            document.title = title
            next()
        },
    }
</script>

<style lang="less">
    .zhuqiao{
        &-item{
            width: 96%;
            height: 140px;
            background: #fff no-repeat;
            border-radius: 3px;
            margin-left: 2%;
            margin-top: 10px;
            background-size:100% 200px;
            color:#000;
            padding-top: 15px;
            position: relative;
            div{
                margin-left: 10px;
            }
            &-title{
                font-size: 18px;
                margin-bottom: 5px;
            }
            &-time{
                display: inline-block;
                margin-top: 5px;
                padding: 5px;
                background: rgba(250, 250, 250, 0.4);
            }
            &-price{
                display: inline-block;
                margin-top: 5px;
                padding: 5px;
                background: rgba(250, 250, 250, 0.4);
            }
            &-check{
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }
    }
</style>